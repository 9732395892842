<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-sm col-1"><img alt="MYS-logo-white" src="assets/svg/MYS-logo-white.svg">
                <div class="bottom-content">
                    <p>© 2023 All Rights Reserved</p>
                    <p> Mind Your Soft</p></div>
            </div>
            <div class="col-sm col-2">
                <div class="content-left">
                    <h1 (click)="emitScroll('infoSection4')" href="#">Services</h1>
                    <a (click)="emitScroll('infoSection4-1')">Web Crawler
                        Technologies</a>
                    <a (click)="emitScroll('infoSection4-2')">Vector Search
                        Engine</a>
                    <a (click)="emitScroll('infoSection4-3')">Fintech
                        Solutions</a>
                    <a (click)="emitScroll('infoSection6')">Custom Development
                        Services</a>
                </div>
                <div class="content-right">
                    <h1 routerLink="/about-us">About us</h1>
                    <h1>Careers</h1>
                </div>
            </div>
            <div class="col-sm col-3"><h1>Contact info</h1>
                <p>office@mindyoursoft.com</p>
                <p>Mind Your Soft Sp. z o.o.</p>
                <p>VAT EU: 8943204335</p>
                <div class="btn-group">
                    <button (click)="openMailTo()"
                            class="btn rounded-pill contact-btn">Contact
                        Us
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
