<div class="about-us-page">
    <div class="container">
        <app-nav-bar (scrollTo)="scroll($event.element)"></app-nav-bar>
        <div class="top">
            <div class="top-section">
                <img alt="about-us" class="about-us-sign" src="assets/svg/about-us-max-1024px.svg">
                <p>
                    At Mind Your Soft, we are a trusted software development company specializing in advanced
                    technologies,
                    including AI, ML, sophisticated vector search engines, and fintech solutions.
                </p>
                <p>
                    With a strong focus on research and a commitment to delivering top-notch solutions,
                    we help businesses grow by providing end-to-end services, from MVP development to maintenance and
                    support.
                </p>
                <h3>Values we live by:</h3>
                <div class="card-group">
                    <div class="card first-card">
                        <div class="card-body">
                            <img alt="expertise-img" src="assets/svg/collaboration.svg">
                            <h4 class="card-title">Collaboration</h4>
                            <p>We value teamwork and collaboration, leveraging the collective expertise of our
                                professionals
                                to
                                deliver innovative solutions for our clients.
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <img alt="research-focused-img" src="assets/svg/continuous-learning.svg">
                            <h4 class="card-title">Continuous Learning</h4>
                            <p>We have a growth mindset, constantly learning and staying up-to-date with the latest
                                technologies
                                and industry trends to provide cutting-edge solutions.</p>
                        </div>
                    </div>
                    <div class="card last-card">
                        <div class="card-body">
                            <img alt="client-oriented-img" src="assets/svg/quality-excellence.svg">
                            <h4 class="card-title">Quality Excellence</h4>
                            <p>We are dedicated to delivering superior quality, ensuring that our solutions meet the
                                highest
                                standards and exceed our clients' expectations.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team-section">
                <h2>Meet our team</h2>
                <div class="card-group team-card-group">
                    <div *ngFor="let member of teamMembers " class="card {{member.gridPosition}}">
                        <div class="card-body">
                            <img alt="member-photo" class="card-img-top" src="{{member.img}}">
                            <h4 class="card-title">{{member.name}}</h4>
                            <p>{{member.position}}</p>
                            <button (click)="window.open(member.linkedin)" *ngIf="member.linkedin!==''"></button>
                        </div>
                    </div>
                </div>
            </div>
            <app-info-section-five (scrollTo)="scroll($event.element)">></app-info-section-five>
        </div>
    </div>
    <app-info-section-eight></app-info-section-eight>
    <div class="container">

        <app-info-section-nine></app-info-section-nine>
        <app-info-section-ten></app-info-section-ten>
    </div>

    <app-footer (scrollTo)="scroll($event.element)"></app-footer>
</div>