import {Component, EventEmitter, HostListener, Output} from '@angular/core';


@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
    resize: boolean = true;
    @Output() scrollTo = new EventEmitter<{ element: string }>
    protected readonly window = window;
    protected readonly navigator = navigator;
    protected readonly open = open;

    constructor() {
        this.onResize()
    }

    emitScroll(el: string) {
        this.scrollTo.emit({element: el});
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resize = window.innerWidth > 770;
    }
}
