import {Component} from '@angular/core';

@Component({
    selector: 'app-info-section-nine',
    templateUrl: './info-section-nine.component.html',
    styleUrls: ['./info-section-nine.component.scss']
})
export class InfoSectionNineComponent {

    protected readonly window = window;

    openMailtoForm() {
        window.location.assign("mailto:office@mindyoursoft.com")
    }

    openInNewWindow(param: string) {
        switch (param) {
            case "map":
                window.open('https://www.google.com/maps/place/Grabiszy%C5%84ska+186,+53-437+Wroc%C5%82aw');
                break
            case "linkedin" :
                window.open('https://cy.linkedin.com/in/lukasz-kowalczyk-44161a224');
                break
        }

    }

}
