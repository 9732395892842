import {Component} from '@angular/core';
import {TechnologySectionData} from "../../../types/technologySectionData.interface";
import {TechnologySectionItem} from "../../../types/technologySectionItem.interface";


@Component({
    selector: 'app-main-panel',
    templateUrl: './main-panel.component.html',
    styleUrls: ['./main-panel.component.scss']
})
export class MainPanelComponent {
    crawlerData = <TechnologySectionData>({
        type: 1,
        title: "Web Crawler Technologies",
        img: "assets/svg/web-crawler-technologies.svg",
        content: "We have developed a powerful program that revolutionizes web indexing and scraping, specifically designed for targeted information retrieval",
        items: [
            <TechnologySectionItem>{
                title: "Distributed",
                img: "assets/svg/distributed.svg",
                content: "Our crawler utilizes a distributed architecture for efficient data retrieval." +
                    "It handles large-scale web scraping tasks, enhancing productivity and accelerating the search process."
            },
            <TechnologySectionItem>{
                title: "Robust",
                img: "assets/svg/robust.svg",
                content: "Our crawler effortlessly handles diverse website structures," +
                    " challenging data formats and dynamic content, extracting information accurately in though web scraping scenarios"
            }]
    })
    vectorData = <TechnologySectionData>({
        type: 2,
        title: "Vector Search Engine",
        img: "assets/svg/vector-search-engine.svg",
        content: "Mind Your Soft excels in developing and utilizing a powerful vector search engine. This innovative technology enables efficient storage, indexing, and management of large embedding vectors generated by deep neural networks and other machine learning models.",
        items: [
            <TechnologySectionItem>{
                title: "Big Data Ready",
                img: "assets/svg/big-data-ready.svg",
                content: "Our vector search solution efficiently handles and processes massive amounts of data, ensuring scalability and optimal performance."
            },
            <TechnologySectionItem>{
                title: "Crazy Fast",
                img: "assets/svg/crazy-fast.svg",
                content: "With unparalleled speed, our solution swiftly matches and retrieves relevant vectors, providing near-instantaneous response time"
            }]
    })
    fintechData = <TechnologySectionData>({
        type: 1,
        title: "Fintech Solutions",
        img: "assets/svg/fintech-solutions.svg",
        content: "Mind Your Soft delivers secure, robust, and scalable fintech solutions that compete with industry leaders like Stripe ans FastSpring. Our localized services offer efficient fees, empowering you to streamline financial processes and drive business growth.",
        items: [
            <TechnologySectionItem>{
                title: "PCI compliant",
                img: "assets/svg/pci-compliant.svg",
                content: "PCI compliance ensures secure handling, processing and storage of payment card data, protecting sensitive financial information and preventing data breaches, fraud and unauthorized access."
            },
            <TechnologySectionItem>{
                title: "High Available",
                img: "assets/svg/highly-availability.svg",
                content: "Mind Your Soft's fintech solutions are renowned for their exceptional availability. The software boasts stability and efficiency, delivering an average uptime to 99.98%"
            }]
    })
    protected readonly window = window;


    scroll(el: string) {
        document.getElementById(el)!.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }
}
