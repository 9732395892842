<div class="content">
    <div class="info-section-9">
        <div class="content">
            <div class="top-content">
                <h1>Visit our office in Wrocław, Poland</h1>

            </div>
            <div class="bottom-content">
                <div class="card-group">
                    <div class="card first-card">
                        <div class="card-body">
                            <h5>
                                Address
                            </h5>
                            <p>

                                Grabiszynska 186<br>Office 2B/4<br> 53-235 Wrocław, Poland
                            </p>

                        </div>
                    </div>
                    <div class="card last-card">
                        <div class="card-body">
                            <h5>Email</h5>
                            <p>
                                office@mindyoursoft.com
                            </p>
                            <button (click)="openMailtoForm()">
                                <div>Drop us a message</div>
                            </button>
                        </div>
                    </div>
                </div>
                <button (click)="openInNewWindow('map')"
                        class="google-maps-btn">

                    <div>Google maps <img alt="arrow-right" src="assets/svg/right-arrow.svg">
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
