import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {MainPanelComponent} from './components/main-panel/main-panel.component';
import {TechnologySectionComponent} from './components/technology-section/technology-section.component';
import {FormsModule} from "@angular/forms";
import {NgOptimizedImage} from "@angular/common";
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { InfoSectionFiveComponent } from './components/info-section/info-section-five/info-section-five.component';
import { InfoSectionEightComponent } from './components/info-section/info-section-eight/info-section-eight.component';
import { InfoSectionNineComponent } from './components/info-section/info-section-nine/info-section-nine.component';
import { InfoSectionTenComponent } from './components/info-section/info-section-ten/info-section-ten.component';

@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        MainPanelComponent,
        TechnologySectionComponent,
        FooterComponent,
        AboutUsComponent,
        InfoSectionFiveComponent,
        InfoSectionEightComponent,
        InfoSectionNineComponent,
        InfoSectionTenComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgOptimizedImage
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
