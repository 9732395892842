import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Output() scrollTo = new EventEmitter<{ element: string }>
    protected readonly window = window;

    emitScroll(el: string) {
        this.scrollTo.emit({element: el})
    }

    openMailTo() {
        window.location.assign('mailto:office@mindyoursoft.com')
    }
}
