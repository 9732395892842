import {Component, Input} from '@angular/core';
import {TechnologySectionData} from "../../../types/technologySectionData.interface";

@Component({
    selector: 'app-technology-section',
    templateUrl: './technology-section.component.html',
    styleUrls: ['./technology-section.component.scss']
})
export class TechnologySectionComponent {
    @Input()
    data!: TechnologySectionData
    protected readonly window = window;
}


