import {Component} from '@angular/core';

@Component({
    selector: 'app-info-section-eight',
    templateUrl: './info-section-eight.component.html',
    styleUrls: ['./info-section-eight.component.scss']
})
export class InfoSectionEightComponent {

    protected readonly window = window;
}
