<div class="technology-section">
    <div class="container">
        <div class="row">
            <div *ngIf="data.type===1" class="col-sm col-img">
                <img class="technology-image" src="{{data.img}}">
            </div>
            <div class="col-sm col-content">
                <h1>
                    {{data.title}}
                </h1>
                <p>
                    {{data.content}}
                </p>
                <div class="container">
                    <div class="row">
                        <div class="col-sm border-col1">
                            <img alt="technology-section-image" src="{{data.items[0].img}}">
                            <h4>{{data.items[0].title}}</h4>
                            <p>{{data.items[0].content}}</p>

                        </div>
                        <div class="col-sm border-col2">
                            <img alt="technology-section-image" src="{{data.items[1].img}}">
                            <h4>{{data.items[1].title}}</h4>
                            <p>{{data.items[1].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.type===2" class="col-sm col-img">
                <img class="technology-image" src="{{data.img}}">
            </div>
        </div>
    </div>
</div>
