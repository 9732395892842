<div class="info-section-8" id="infoSection8">
    <div class="container">
        <img alt="we-are-hiring-banners" src="assets/svg/we-are-hiring.svg">
        <h3> We seek talented developers passionate about AI & ML, FinTech, and cutting-edge technologies in
            software
            development.</h3>
        <button (click)="window.open('https://pracodawcy.pracuj.pl/company/1074129676')" class="btn btn-primary rounded-pill" data-mdb-ripple-color="dark"
                type="button">
            Check offers on Pracuj!
        </button>
        <h3><span style="color: #0174D6">Benefits of working with us:</span></h3>
        <div class="card-group">
            <div class="card first-card">
                <div class="card-body">
                    <img alt="hybrid-work-icon" src="assets/svg/hybrid-work.svg">
                    <h4 class="card-title">Hybrid Work</h4>
                    <p>Mind Your Soft Our ensures flexible work arrangements: office and partially remote
                        options
                        available.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <img alt="growth-as-a-specialist-icon" src="assets/svg/growth-as-a-specialist.svg">
                    <h4 class="card-title">Growth as a specialist</h4>
                    <p>Our researchers prioritize continuous research and study of the latest AI & ML
                        technologies
                        to
                        stay ahead of the curve.</p>
                </div>
            </div>
            <div class="card last-card">
                <div class="card-body">
                    <img alt="global-projects-icon" src="assets/svg/global-projects.svg">
                    <h4 class="card-title">Global projects</h4>
                    <p>Make a meaningful impact on the daily lives of thousands of people around the world
                        through
                        the
                        products we create.</p>
                </div>
            </div>
        </div>
    </div>
</div>