<div class="main-panel">
    <div class="content">
        <app-nav-bar (scrollTo)="scroll($event.element)"></app-nav-bar>
        <div class="info-section-1">
            <div class="container">
                <div class="row">
                    <div class="col-sm col-text">
                        <p class="headline">Outsource your technology solutions:</p>
                        <h1>Let us turn your
                            <span class="ideas_highlight">ideas</span>
                            into reality!
                        </h1>
                        <p>Maximize the potential of your software with our cutting-edge
                            solutions
                            and proficient team.
                        </p>
                        <button (click)="scroll('infoSection10')" class="btn btn-primary rounded-pill"
                                data-mdb-ripple-color="dark"
                                type="button">
                            Let's
                            talk
                        </button>
                    </div>
                    <div class="col-sm col-img"><img alt="main-illustration" src="assets/svg/main-illustration.svg">
                    </div>
                </div>
            </div>
        </div>

        <div class="info-section-2">
            <div class="card-group">
                <div class="card first-card">
                    <div class="card-body">
                        <img alt="expertise-img" src="assets/svg/expertise.svg">
                        <h4 class="card-title">Expertise</h4>
                        <p>Mind Your Soft excels in advanced technologies like AI, ML, vector search,
                            fintech, and
                            custom web development.</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <img alt="research-focused-img" src="assets/svg/research-focused.svg">
                        <h4 class="card-title">Research-Focused</h4>
                        <p>We have specialists to research, constantly exploring and creating cutting
                            edge
                            technologies and industry trends.</p>
                    </div>
                </div>
                <div class="card last-card">
                    <div class="card-body">
                        <img alt="client-oriented-img" src="assets/svg/client-oriented.svg">
                        <h4 class="card-title">Client-Oriented</h4>
                        <p>We focus on understanding clients needs, delivering personalized solutions,
                            and
                            providing exceptional ongoing support.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-section-3">
            <h2>Mind Your Soft:</h2>
            <img alt="country-banner" src="assets/svg/country-banner-max.svg">
        </div>
        <div class="info-section-4" id="infoSection4">
            <app-technology-section [data]="crawlerData" id="infoSection4-1"></app-technology-section>
            <app-technology-section [data]="vectorData" id="infoSection4-2"></app-technology-section>
            <app-technology-section [data]="fintechData" id="infoSection4-3"></app-technology-section>
        </div>
        <app-info-section-five (scrollTo)="scroll($event.element)">></app-info-section-five>
        <div class="info-section-5" id="infoSection5">
          <div class="container">
            <div class="row">
              <div class="col-sm text-col">
                <h3>Check out  <img class="lenso-ai-logo" alt="lenso-ai-logo" src="assets/svg/lenso_ai_logo.svg"></h3>
                <h5>New project using cutting-edge AI and ML algorithms for innovative vector search, developed and supported by Mind Your Soft.</h5>
                <p>With Lenso.ai, you can easily search for places, people, duplicates, related, or similar images. The reverse image search process we developed is significantly more accurate and efficient than traditional image search. All you need is to drop an image on AI-powered Lenso.ai and select the specific area on the image that you are most interested in.</p>
                <button (click)="window.open('https://lenso.ai/en')" class="btn btn-primary rounded-pill"
                        data-mdb-ripple-color="dark"
                        type="button">
                  Try Image Search in Lenso.ai
                </button>
              </div>
              <div class="col-sm col-img">
              <img alt="lenso-preview" src="assets/svg/lenso-preview.webp">
              </div>
            </div>

          </div>
        </div>
        <div class="info-section-6" id="infoSection6">
            <div class="container">
                <div class="row">
                    <div class="col-sm col-img">
                        <img alt="custom-development-services" src="assets/svg/custom-development-services.svg">
                    </div>

                    <div class="col-sm text-col">
                        <h3>Custom Development Services</h3>
                        <h5>At Mind Your Soft, we offer custom development services tailored to your specific
                            needs.</h5>
                        <p>Our comprehensive solutions cover the entire product lifecycle, from idea refinement and
                            design
                            to
                            development, maintenance, and support.
                            Whether you require a resource-efficient MVP to test your business idea or ongoing tech
                            support
                            for
                            your
                            existing project, we have the expertise to deliver exceptional results.</p>
                        <p>Trust us to handle the technical aspects while you focus on growing your business and
                            exploring
                            new
                            features.</p>
                        <button (click)="scroll('infoSection10')" class="btn btn-primary rounded-pill"
                                data-mdb-ripple-color="dark"
                                type="button">
                            Partner with Mind Your Soft!
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-section-7">
            <div class="row">
                <div class="card top-left">
                    <div class="card-body">
                        <img alt="product-discovery" src="assets/svg/product-discovery.svg">
                        <h4 class="card-title">Product Discovery</h4>
                        <p>Checking the viability of your product, refining ideas, creating pitch deck, and shaping
                            the
                            user-centric project.</p>
                    </div>
                </div>
                <div class="card top-right">
                    <div class="card-body">
                        <img alt="product-design" src="assets/svg/product-design.svg">
                        <h4 class="card-title">Product Design</h4>
                        <p>Understanding your users and business needs. Building a functional interface for any
                            device
                            and
                            platform.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card bottom-left">
                    <div class="card-body">
                        <img alt="software-development" src="assets/svg/software-development.svg">
                        <h4 class="card-title">Software Development</h4>
                        <p>We use the best global practices and advanced stable tech stack for scalable software &
                            web
                            development.</p>
                    </div>
                </div>
                <div class="card bottom-right">
                    <div class="card-body">
                        <img alt="maintenance-and-support" src="assets/svg/maintenance-and-support.svg">
                        <h4 class="card-title">Maintenance & Support</h4>
                        <p>Regular software updates, implementing new features, and technical onboarding for you and
                            your
                            team members.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-section-technologies">
            <h3> Building software on reliable tech:</h3>
            <div class="container">
                <div class="col-sm">
                    <img alt="redis-logo" src="assets/svg/redis-logo.svg">
                    <img alt="linux-logo" src="assets/svg/linux-logo.svg">
                    <img alt="mysql-logo" src="assets/svg/mysql-logo.svg">
                    <img alt="java-logo" src="assets/svg/java-logo.svg">
                    <img alt="angular-logo" src="assets/svg/angular-logo.svg">
                    <img alt="c-logo" src="assets/svg/c-logo.svg">
                </div>
            </div>
        </div>
    </div>
    <app-info-section-eight></app-info-section-eight>
    <div class="content">

        <app-info-section-nine></app-info-section-nine>

        <app-info-section-ten></app-info-section-ten>
    </div>
    <app-footer (scrollTo)="scroll($event.element)"></app-footer>
</div>
