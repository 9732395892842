<div class="info-section-10" id="infoSection10">
    <div class="container"></div>
    <div class="row">
        <div class="col-sm col-img">
            <img alt="collaborate-img" src="assets/svg/collaborate.svg">
        </div>
        <div class="col-sm content">
            <h2>Let's collaborate!</h2>
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="card-title">Contact our support manager:</h4>
                        <p>office@mindyoursoft.com</p>
                    </div>
                    <button (click)="openMailTo()"><img alt="arrow-right" src="assets/svg/right-arrow.svg"></button>
                </div>
            </div>
        </div>
    </div>
</div>