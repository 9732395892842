<div class="nav-bar">
    <div class="right-section">
        <img class="mys-logo" routerLink='' src="assets/svg/mys-logo-blue.svg">
        <button (click)="emitScroll('infoSection8')" class="btn btn-outline-primary rounded-pill"
                data-mdb-ripple-color="dark" type="button">
            We are hiring!
        </button>
    </div>
    <div *ngIf="resize" class="left-section">
        <a (click)="emitScroll('infoSection4')" href="javascript:void(0);" routerLink="">Services</a>
        <a href="javascript:void(0);" routerLink='/about-us'>About us</a>
        <a (click)="window.open('https://pracodawcy.pracuj.pl/company/1074129676')"
           href="javascript:void(0);">Careers</a>
        <button (click)="emitScroll('infoSection10')" class="btn btn-outline-primary rounded-pill"
                data-mdb-ripple-color="dark" type="button">
            Contact us
        </button>
    </div>
</div>

