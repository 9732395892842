import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-info-section-five',
    templateUrl: './info-section-five.component.html',
    styleUrls: ['./info-section-five.component.scss']
})
export class InfoSectionFiveComponent {
    @Output() scrollTo = new EventEmitter<{ element: string }>

    emitScroll(el: string) {
        this.scrollTo.emit({element: el});
    }
}
