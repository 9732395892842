import {Component} from '@angular/core';

@Component({
    selector: 'app-info-section-ten',
    templateUrl: './info-section-ten.component.html',
    styleUrls: ['./info-section-ten.component.scss']
})
export class InfoSectionTenComponent {

    openMailTo() {
        window.location.assign("mailto:office@mindyoursoft.com")
    }
}
